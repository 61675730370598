import { Grid2, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { usuarioContext } from "../../App";
import InformacionBinariosDirectos from "../Componentes/InformacionBinariosDirectos";
import TablaReferidos from "../Componentes/TablaReferidos";
import HistorialComisiones from "../Componentes/HistorialComisiones";
import GraficoBinario from "../Componentes/GraficoBinario";
import { obtenerDoc } from "../../Servicios/BD/obtenerDoc";

const SeccionPlanCorredor = () => {

    const cData = useContext(usuarioContext)
    // console.log(cData);
    const usuario = cData?.usuario;
    let codigo = usuario.fecha;

    // console.log("Mostrar izquierdos y derechos: ", usuario?.izquierdos, usuario?.derechos);

    const [link, setLink] = useState(""); //Para manejar el link que se va a generar
    const [openDialog, setOpenDialog] = useState(false); //Para manejar el dialogo
    const [side, setSide] = useState(""); //Para manejar el lado de referido izq o der
    const [copied, setCopied] = useState(false); //Para ver si se copio el link
    const [tieneDirectos, setTieneDirectos] = useState(false);

    // Estados para almacenar los usuarios en las ramas izquierda y derecha
    const [usuariosIzquierdos, setUsuariosIzquierdos] = useState([]);
    const [usuariosDerechos, setUsuariosDerechos] = useState([]);


    const [loadingIzquierdos, setLoadingIzquierdos] = useState(false);
    const [loadingDerechos, setLoadingDerechos] = useState(false);



    const handleGenerateLink = (side) => {
        // Generar un codigo aleatorio con la fecha y hora actual
        // Para hacer las pruebas en localhost
        // const linkReferido = `${window.location.origin}/true?code=${codigo}&ref=${usuario.id}&side=${side}`;

        const linkReferido = `https://billetera.foxplor.app/true?code=${codigo}&ref=${usuario.id}&side=${side}`;
        setLink(linkReferido);
        setSide(side);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // Función para obtener todos los usuarios en una rama recursivamente
    const obtenerUsuariosEnRama = async (col, id) => {
        const usuarios = [];

        const obtenerRecursivo = async (id) => {
            const respuesta = await obtenerDoc(col, id);
            // console.log("Respuesta de obtenerDoc:", respuesta); // Verificar si está obteniendo datos
            if (respuesta.res && respuesta.data) {
                const usuario = { id, ...respuesta.data };
                // console.log("Usuario obtenido:", usuario); // Verificar usuario obtenido
                usuarios.push(usuario);

                // Llamada recursiva para los hijos izquierdo y derecho
                if (usuario.directo_izquierdo) {
                    await obtenerRecursivo(usuario.directo_izquierdo);
                }
                if (usuario.directo_derecho) {
                    await obtenerRecursivo(usuario.directo_derecho);
                }
            }
        };

        await obtenerRecursivo(id);
        // console.log("Usuarios obtenidos para rama:", usuarios); // Verificar lista final
        return usuarios;
    };
    // Función para cargar los usuarios de las ramas izquierda y derecha
    const cargarDatosArbol = async () => {
        if (usuario) {
            // console.log("Usuario actual:", usuario); // Verificar usuario completo

            // Rama izquierda
            if (usuario.directo_izquierdo) {
                setLoadingIzquierdos(true); // Inicia el estado de carga
                // console.log("Iniciando obtención de rama izquierda...");
                const izq = await obtenerUsuariosEnRama("usuarios", usuario.directo_izquierdo);
                setUsuariosIzquierdos(izq);
                setLoadingIzquierdos(false); // Termina el estado de carga

            } else {
                // console.log("Sin directo izquierdo");
                setUsuariosIzquierdos([]);
            }

            // Rama derecha
            if (usuario.directo_derecho) {
                setLoadingDerechos(true); // Inicia el estado de carga
                // console.log("Iniciando obtención de rama derecha...");
                const der = await obtenerUsuariosEnRama("usuarios", usuario.directo_derecho);
                setUsuariosDerechos(der);
                setLoadingDerechos(false); // Termina el estado de carga
            } else {
                // console.log("Sin directo derecho");
                setUsuariosDerechos([]);
            }
        }
    };

    useEffect(() => {
        // if (
        //     (usuario.directos_derechos && usuario.directos_derechos.length > 0) ||
        //     (usuario.directos_izquierdos && usuario.directos_izquierdos.length > 0)
        // ) {
        //     setTieneDirectos(true);
        // } else {
        //     setTieneDirectos(false);
        // }

        // Cargar datos del árbol
        cargarDatosArbol();
    }, [usuario]);

    console.log("Tiene directos: ", tieneDirectos);
    console.log("Usuarios izquierdos: ", usuariosIzquierdos);
    console.log("Usuarios derechos: ", usuariosDerechos);






    return (
        <Grid2 container spacing={2} sx={{ padding: 2, display: 'flex', alignItems: 'center', }}>
            <Grid2 container xs={12}>
                <Grid2 item xs={12} sx={{ margin: 0, paddingLeft: 3 }}>
                    <Typography color="#fff">Referir usuario</Typography>
                </Grid2>
            </Grid2>
            <Grid2 container xs={12} sx={{ padding: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>

                <Grid2 item xs={12}>
                    <Button
                        variant="contained"
                        sx={{
                            width: '140px',
                            px: '15px',
                            backgroundColor: '#129900',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#0e7b00'
                            }
                        }}
                        onClick={() => handleGenerateLink("left")}>
                        Por izquierda
                    </Button>
                </Grid2>
                <Grid2 item xs={12}>
                    <Button variant="contained"
                        sx={{ width: '140px', px: '15px', backgroundColor: '#6100ff', color: '#fff', '&:hover': { backgroundColor: '#4d00b3' } }} onClick={() => handleGenerateLink("right")}>
                        Por derecha
                    </Button>
                </Grid2>
            </Grid2>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle color="#fff">Link generado: Lado {side === "left" ? "Izquierdo" : "Derecho"}</DialogTitle>
                <DialogContent>
                    <Typography color="#fff" variant="body1">{link}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        navigator.clipboard.writeText(link);
                        setCopied(true);
                        setTimeout(() => setCopied(false), 5000);
                    }} sx={{ background: copied ? '#4CAF50' : '#2196F3', color: '#fff', '&:hover': { background: copied ? '#388E3C' : '#1976D2' } }}>
                        {copied ? "Link copiado" : "Copiar link"}
                    </Button>

                    <Button onClick={handleCloseDialog} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <InformacionBinariosDirectos ></InformacionBinariosDirectos> */}
            <InformacionBinariosDirectos
                usuariosIzquierdos={usuariosIzquierdos}
                usuariosDerechos={usuariosDerechos}
            />

            {/* Componente para mostrar el arbol binario de manera grafica solo si el usuario tiene directos */}
            {/* {tieneDirectos && <GraficoBinario></GraficoBinario>} */}
            <GraficoBinario></GraficoBinario>

            <TablaReferidos lado="izquierda" usuarios={usuariosIzquierdos}></TablaReferidos>
            <TablaReferidos lado="derecha" usuarios={usuariosDerechos}></TablaReferidos>

            {/* Componente que te muestra un historial de comisiones */}
            <HistorialComisiones></HistorialComisiones>
        </Grid2>
    );
};

export default SeccionPlanCorredor