// Aqui se mostrará la informacion de los binarios directos
import { useState, useContext } from "react";
import { useEffect } from "react";
import { Grid2, Typography, Button, Box } from "@mui/material";
import { usuarioContext } from "../../App";
import { obtenerDoc } from "../../Servicios/BD/obtenerDoc";
import logo from '../../Recursos/logo_redondo.svg';
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";


const InformacionBinariosDirectos = ({ usuariosIzquierdos, usuariosDerechos }) => {
    // 
    const cData = useContext(usuarioContext)
    // console.log(cData);
    const idUsuario = cData?.usuario.id;
    const [usuario, setUsuario] = useState(cData?.usuario);
    // console.log('Informacion_Binarios_Directos', usuario)
    // console.log(usuario.directo_izquierdo);


    const [directoIzquierdo, setDirectoIzquierdo] = useState(null);
    const [directoDerecho, setDirectoDerecho] = useState(null);
    // Para los puntos por izquierda y por derecha
    const [puntosIzquierda, setPuntosIzquierda] = useState(0);// Puntos por izquierda
    const [puntosDerecha, setPuntosDerecha] = useState(0);// Puntos por derecha
    // Para las acciones en izquierda y derecha
    const [accionesIzquierda, setAccionesIzquierda] = useState(0);// Acciones por izquierda
    const [accionesDerecha, setAccionesDerecha] = useState(0);// Acciones por derecha
    // Para contar el numero de cuentas a izquierda y derecha
    const [cuentasIzquierda, setCuentasIzquierda] = useState(0);// Cuentas a izquierda
    const [cuentasDerecha, setCuentasDerecha] = useState(0);// Cuentas a derecha
    // Para las comisiones
    const [comisiones, setComisiones] = useState(0);
    const [binarioActivo, setBinarioActivo] = useState(false);

    const [totalAccionesIzquierda, setTotalAccionesIzquierda] = useState(0);
    const [usuariosConAccionesIzquierda, setUsuariosConAccionesIzquierda] = useState(0);
    const [totalAccionesDerecha, setTotalAccionesDerecha] = useState(0);
    const [usuariosConAccionesDerecha, setUsuariosConAccionesDerecha] = useState(0);

    // Calcular cuando los usuarios en las ramas cambien
    useEffect(() => {
        const calcularAcciones = (usuarios) => {
            let totalAcciones = 0;
            let usuariosConAcciones = 0;

            usuarios.forEach(usuario => {
                if (usuario.acciones > 0) {
                    totalAcciones += usuario.acciones;
                    usuariosConAcciones++;
                }
            });

            return { totalAcciones, usuariosConAcciones };
        };

        const { totalAcciones: totalIzq, usuariosConAcciones: usuariosIzq } = calcularAcciones(usuariosIzquierdos);
        const { totalAcciones: totalDer, usuariosConAcciones: usuariosDer } = calcularAcciones(usuariosDerechos);

        setTotalAccionesIzquierda(totalIzq);
        setUsuariosConAccionesIzquierda(usuariosIzq);
        setTotalAccionesDerecha(totalDer);
        setUsuariosConAccionesDerecha(usuariosDer);
    }, [usuariosIzquierdos, usuariosDerechos]);

    // console.log(totalAccionesIzquierda, usuariosConAccionesIzquierda, totalAccionesDerecha, usuariosConAccionesDerecha);


    useEffect(() => {

        const fetchDirectos = async () => {
            // if (idUsuario) {
            //     const userData = await obtenerDoc('usuarios', idUsuario);
            //     if (userData.data) {
            //         setUsuario(userData.data);
            //     }
            // }
            // if (usuario.directo_izquierdo_activo) {
            //     const directoIzquierdoData = await obtenerDoc('usuarios', usuario.directo_izquierdo_activo);

            //     if (directoIzquierdoData.data) {
            //         setDirectoIzquierdo(directoIzquierdoData.data);
            //     } else {
            //         console.log("No such document!");
            //     }
            // } else 
            if (usuario.directos_izquierdos && usuario.directos_izquierdos.length > 0) {
                const directoIzquierdoData = await obtenerDoc('usuarios', usuario.directos_izquierdos[0]);
                // const directoIzquierdoData = await obtenerDoc('usuarios', usuario.directo_izquierdo);
                if (directoIzquierdoData.data) {
                    setDirectoIzquierdo(directoIzquierdoData.data);
                }
            }
            // else {
            //     if (usuario.directo_izquierdo) {
            //         const directoIzquierdoData = await obtenerDoc('usuarios', usuario.directo_izquierdo);
            //         if (directoIzquierdoData.data) {
            //             setDirectoIzquierdo(directoIzquierdoData.data);
            //         }
            //     }
            // }
            // if (usuario.directo_derecho_activo) {
            //     const directoDerechoData = await obtenerDoc('usuarios', usuario.directo_derecho_activo);
            //     if (directoDerechoData.data) {
            //         setDirectoDerecho(directoDerechoData.data);
            //     } else {
            //         console.log("No such document!");
            //     }
            // } else 
            if (usuario.directos_derechos && usuario.directos_derechos.length > 0) {
                const directoDerechoData = await obtenerDoc('usuarios', usuario.directos_derechos[0]);
                if (directoDerechoData.data) {
                    setDirectoDerecho(directoDerechoData.data);
                }
            }
            // else {
            //     if (usuario.directo_derecho) {
            //         const directoDerechoData = await obtenerDoc('usuarios', usuario.directo_derecho);
            //         if (directoDerechoData.data) {
            //             setDirectoDerecho(directoDerechoData.data);
            //         }
            //     }
            // }
        };

        if (usuario.puntos_derecho) {
            setPuntosDerecha(usuario.puntos_derecho)
        }
        if (usuario.puntos_izquierdo) {
            setPuntosIzquierda(usuario.puntos_izquierdo)
        }
        // if (usuario.comisiones) {
        //     setComisiones(usuario.comisiones)
        // }
        if (usuario.bonificaciones) {
            setComisiones(usuario.bonificaciones)
        }
        if (usuario.directo_izquierdo_activo && usuario.directo_derecho_activo) {
            setBinarioActivo(true);// Si tiene directos activos => Binario activo
        }
        if (usuario.directos_derechos && usuario.directos_derechos.length > 0) {
            // Contar las cuentas a derecha que sean directos
            setCuentasDerecha(usuario.directos_derechos.length)
        }
        if (usuario.directos_izquierdos && usuario.directos_izquierdos.length > 0) {
            // Contar las cuentas a izquierda que sean directos
            setCuentasIzquierda(usuario.directos_izquierdos.length)
        }

        if (usuario.total_acciones_derecha) {
            setAccionesDerecha(usuario.total_acciones_derecha);
        }

        if (usuario.total_acciones_izquierda) {
            setAccionesIzquierda(usuario.total_acciones_izquierda);
        }

        fetchDirectos();
    }, [usuario.directo_izquierdo, usuario.directo_derecho, usuario.puntos_izquierdo, usuario.puntos_derecho, usuario.bonificaciones, puntosIzquierda, puntosDerecha, usuario,]);


    // console.log('accionesDerecha', accionesDerecha);
    // console.log('accionesIzquierda', accionesIzquierda);
    return (
        <>
            <Grid2 container
                size={12}
                sx={{
                    p: 3,
                    background: 'linear-gradient(180deg, rgba(217, 217, 217, 0.1) 0%, rgba(115, 115, 115, 0.1) 100%)',
                    border: 1,
                    borderColor: '#ffffff40',
                    borderRadius: 3,
                    pb: 4
                }}
            >
                <Grid2 container size={12} sx={{ pr: 4, mb: 0 }}>
                    <Typography sx={{ color: '#fff', fontSize: 24, fontWeight: 800 }}>Información de los Binarios</Typography>
                </Grid2>
                {/* Un icono que le indica al usuario si tiene sus dos directos o no (BINARIO ACTIVO O NO ACTIVO) */}
                <Grid2 item xs={12} md={6}
                    sx={{ margin: '20px 0px 20px 0px' }}
                >
                    <Button variant="outlined"
                        sx={{ color: '#fff', padding: 2, margin: 0, border: binarioActivo ? '2px #0e7b0080 solid' : '2px #fc600080 solid', '&:hover': { backgroundColor: binarioActivo ? '#0e7b0050' : '' } }}>
                        <img src={logo} width={20} height={'auto'} alt="logo" style={{ marginRight: '10px', }} />
                        {binarioActivo ? "Binario activo" : "Binario no activo"}
                    </Button>
                </Grid2>

                <Grid2 container size={12}>
                    <Grid2 container size={12} sx={{ justifyContent: 'flex-start' }}>
                        <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 600, margin: '0px 5px 0px 0px' }}>Directo izquierdo:</Typography>
                        <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 200 }}>
                            {directoIzquierdo ? `${directoIzquierdo.nombre} ` : 'No hay registro'}
                        </Typography>
                        <Typography sx={{ color: '#00ff61', fontSize: 16, fontWeight: 400, margin: '0px 0px 0px 5px' }}>
                            {directoIzquierdo ? ` (${directoIzquierdo.correo})` : ''}
                        </Typography>
                    </Grid2>

                    <Grid2 container size={12} sx={{ justifyContent: 'flex-start' }}>
                        <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 600, margin: '0px 5px 0px 0px' }}>Directo derecho:</Typography>
                        <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 200 }}>
                            {directoDerecho ? `${directoDerecho.nombre} ` : 'No hay registro'}
                        </Typography>
                        <Typography sx={{ color: '#03A9F4', fontSize: 16, fontWeight: 400, margin: '0px 0px 0px 5px' }}>
                            {directoDerecho ? ` (${directoDerecho.correo})` : ''}
                        </Typography>
                    </Grid2>
                    <Grid2 container size={12} sx={{ justifyContent: 'flex-start' }}>
                        <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 600, margin: '0px 5px 0px 0px' }}>Comisiones:</Typography>
                        <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 200 }}>${comisiones.toFixed(2)}</Typography>
                    </Grid2>
                </Grid2>

            </Grid2>

            {/* ***********Cuadros de informacion*********** */}
            <Grid2 container spacing={2} sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', marginTop: '20px' }}>
                {/* Cuadro izquierda */}
                <Grid2
                    container
                    item
                    xs={12}
                    md={6}
                    sx={{
                        border: '1px solid #ccc',
                        borderRadius: 2,
                        backgroundColor: '#1e1e1e',
                        display: 'flex',
                        alignItems: 'stretch',
                        flexDirection: 'row', // Siempre en fila
                        // Ancho de 50% de la pantalla para pantallas grandes y del 100% para pantallas pequeñas
                        width: { xs: '100%', md: '49%' },
                    }}
                >
                    {/* Icono de flecha izquierda */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#000',
                            width: '40px',
                            borderRadius: '10px 0px 0px 10px',
                        }}
                    >
                        <ArrowBackIos sx={{ color: '#fff', fontSize: 20 }} />
                    </Box>

                    {/* Contenido del cuadro */}
                    <Grid2
                        container
                        spacing={2}
                        sx={{
                            flexGrow: 1,
                            padding: 1,
                            alignItems: 'center',
                            justifyContent: { xs: 'center', md: 'space-around' },
                        }}
                    >
                        {/* Subcuadro: Total */}
                        <Grid2 item xs={12} md={4}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 600 }}>Total</Typography>
                                <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 200 }}>${totalAccionesIzquierda}</Typography>
                            </Box>
                        </Grid2>
                        {/* Subcuadro: Disponible */}
                        <Grid2 item xs={12} md={4}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 600 }}>Disponible</Typography>
                                <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 200 }}>${puntosIzquierda}</Typography>
                            </Box>
                        </Grid2>
                        {/* Subcuadro: Cuentas */}
                        <Grid2 item xs={12} md={4}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 600 }}>Cuentas</Typography>
                                <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 200 }}>{usuariosConAccionesIzquierda}</Typography>
                            </Box>
                        </Grid2>
                    </Grid2>
                </Grid2>

                {/* Cuadro derecha */}
                <Grid2
                    container
                    item
                    xs={12}
                    md={6}
                    sx={{
                        border: '1px solid #ccc',
                        borderRadius: 2,
                        backgroundColor: '#1e1e1e',
                        display: 'flex',
                        alignItems: 'stretch',
                        flexDirection: 'row', // Siempre en fila
                        width: { xs: '100%', md: '49%' },

                    }}
                >
                    {/* Contenido del cuadro */}
                    <Grid2
                        container
                        spacing={2}
                        sx={{
                            flexGrow: 1,
                            padding: 1,
                            alignItems: 'center',
                            justifyContent: { xs: 'center', md: 'space-around' },
                        }}
                    >
                        {/* Subcuadro: Total */}
                        <Grid2 item xs={12} md={4}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 600 }}>Total</Typography>
                                <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 200 }}>${totalAccionesDerecha}</Typography>
                            </Box>
                        </Grid2>
                        {/* Subcuadro: Disponible */}
                        <Grid2 item xs={12} md={4}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 600 }}>Disponible</Typography>
                                <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 200 }}>${puntosDerecha}</Typography>
                            </Box>
                        </Grid2>
                        {/* Subcuadro: Cuentas */}
                        <Grid2 item xs={12} md={4}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 600 }}>Cuentas</Typography>
                                <Typography sx={{ color: '#fff', fontSize: 16, fontWeight: 200 }}>{usuariosConAccionesDerecha}</Typography>
                            </Box>
                        </Grid2>
                    </Grid2>

                    {/* Icono de flecha derecha */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#000',
                            width: '40px',
                            borderRadius: '0px 10px 10px 0px',
                        }}
                    >
                        <ArrowForwardIos sx={{ color: '#fff', fontSize: 20 }} />
                    </Box>
                </Grid2>
            </Grid2>
            {/* ********************** */}
        </>
    );
}

export default InformacionBinariosDirectos;