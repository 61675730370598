import React, { useContext, useEffect, useState, useRef } from "react";
import { Typography, Grid2, Button, CircularProgress, Modal, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { usuarioContext } from "../../App";
import { doc, getDoc } from "firebase/firestore";
import { fire } from "../../fire";
import Tree from "react-d3-tree"; // Librería para graficar
import logo from '../../Recursos/logo_redondo.svg';
import { Box } from "@mui/system";
import DialogoArbol from "./GraficoUsuario";

const GraficoBinario = () => {
    const cData = useContext(usuarioContext);
    const usuario = cData?.usuario;

    const [arbol, setArbol] = useState(null);
    const [nodosColapsados, setNodosColapsados] = useState(new Set()); // Estado de nodos colapsados
    const [nodoSeleccionado, setNodoSeleccionado] = useState(null); // Nodo seleccionado
    const [nodoSeleccionadoArbol, setNodoSeleccionadoArbol] = useState(null); // Nodo seleccionado para el árbol
    const [nivelMaximo, setNivelMaximo] = useState(0);  // Nivel máximo calculado
    const [nivelVisible, setNivelVisible] = useState(3);  // Inicialmente, mostrar hasta el tercer nivel
    const [hayMasNiveles, setHayMasNiveles] = useState(false);

    const [cargando, setCargando] = useState(false); // Estado de carga

    // Para situar el árbol en el centro
    const [translateX, setTranslateX] = useState(0);
    const containerRef = useRef(null);

    const [modalAbierto, setModalAbierto] = useState(false); //Para manejar el modal
    const [modalArbolAbierto, setModalArbolAbierto] = useState(false); // Estado para abrir/cerrar el modal

    // Función para construir el árbol con un límite de niveles
    const construirArbol = async (idUsuario, nivel = 0) => {
        if (!idUsuario) return { arbol: null, maxNivel: nivel };  // Asegurarse de devolver también el nivel máximo

        setCargando(true); // Mostrar carga mientras se construye el árbol
        const docRef = doc(fire, "usuarios", idUsuario);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) return { arbol: null, maxNivel: nivel };  // Si no existe el documento, devolver null

        const datos = docSnap.data();
        const hijoIzquierdo = await construirArbol(datos.directo_izquierdo, nivel + 1);
        const hijoDerecho = await construirArbol(datos.directo_derecho, nivel + 1);

        // Calcular la profundidad máxima de los nodos hijos
        const maxNivelHijos = Math.max(
            hijoIzquierdo ? hijoIzquierdo.maxNivel : nivel,
            hijoDerecho ? hijoDerecho.maxNivel : nivel
        );

        const arbol = {
            name: datos.nombre,
            id: idUsuario,
            correo: datos.correo,
            attributes: {
                Correo: datos.correo,
                Acciones: datos.acciones ? datos.acciones : 0,
            },
            children: [hijoIzquierdo.arbol, hijoDerecho.arbol].filter(Boolean), // Filtramos si no existen hijos
        };

        return { arbol, maxNivel: Math.max(maxNivelHijos, nivel) };  // Devolvemos el árbol y el nivel máximo calculado
    };

    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        // Detectamos si es un dispositivo táctil
        const checkTouchDevice = () => {
            if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
                setIsTouchDevice(true); // Si es un dispositivo táctil, lo actualizamos
            } else {
                setIsTouchDevice(false); // Si no lo es, lo actualizamos a false
            }
        };

        checkTouchDevice(); // Verificamos al cargar
        window.addEventListener('resize', checkTouchDevice); // Actualizamos al cambiar el tamaño de la ventana

        return () => {
            window.removeEventListener('resize', checkTouchDevice);
        };
    }, []);

    useEffect(() => {
        if (usuario?.id) {
            construirArbol(usuario.id).then(({ arbol, maxNivel }) => {
                setArbol(arbol);
                setNivelMaximo(maxNivel);  // Guardar el nivel máximo calculado
                setNivelVisible(3);  // Establecer inicialmente hasta el tercer nivel
                setHayMasNiveles(maxNivel > 3); // Si el nivel máximo es mayor que 3, hay más niveles
                setCargando(false);
            });
        }
    }, [usuario]);

    // console.log("hayMasNiveles", hayMasNiveles);

    useEffect(() => {
        // Función para actualizar el valor de translateX basado en el ancho del contenedor
        const updateTranslateX = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                // console.log("Ancho del contenedor:", containerWidth);
                setTranslateX(containerWidth / 2);
            }
        };

        updateTranslateX();
        window.addEventListener('resize', updateTranslateX);

        return () => {
            window.removeEventListener('resize', updateTranslateX);
        };
    }, []);


    // Función para manejar el clic en el texto de un nodo
    const handleNodeClick = (nodeData) => {
        console.log("Nodo seleccionado:", nodeData);
        setNodoSeleccionado(nodeData); // Guardar datos del nodo seleccionado
        setModalAbierto(true);
    };

    // Función para manejar el clic en un nodo del árbol
    const handleG = (nodeData) => {
        console.log("Nodo seleccionado:", nodeData);
        setNodoSeleccionadoArbol(nodeData);
        setModalArbolAbierto(true);
    }

    const cerrarModal = () => {
        setModalAbierto(false);
    };

    const cerrarModalArbol = () => {
        setModalArbolAbierto(false);
    };

    // Función para mostrar el siguiente nivel
    const expandirNivel = () => {
        if (nivelVisible < nivelMaximo) {
            setNivelVisible(nivelVisible + 1); // Incrementar nivel visible
        }
    };

    // Función para procesar los nodos y limitar la cantidad de niveles visibles
    const procesarNodos = (nodo, nivel = 0) => {
        if (nivel >= nivelVisible) {
            return { ...nodo, children: [] };  // No mostrar hijos si se ha alcanzado el nivelVisible
        }

        return {
            ...nodo,
            children: nodo.children ? nodo.children.map((child) => procesarNodos(child, nivel + 1)) : [],
        };
    };


    // Función para renderizar un nodo personalizado
    const renderCustomNodeElement = ({ nodeDatum }) => {
        const isSelected = nodoSeleccionado?.id === nodeDatum.id; // Comparar IDs
        const esNodoRaiz = nodeDatum.id === usuario?.id; // Condición si es nodo raíz
        // Verificar si pertenece a tramo izquierdo o derecho
        // const puntosIzq = usuario?.tramo_izquierdo.some(
        //     (tramo) => tramo.idHijo === nodeDatum.id
        // );
        // const puntosDer = usuario?.tramo_derecho.some(
        //     (tramo) => tramo.idHijo === nodeDatum.id
        // );

        // const iconColor = esNodoRaiz ? "#000" : (nodeDatum.attributes?.Acciones > 0 ? "#000" : "#6d828520"); // Color gris si no tiene acciones
        const iconColor = nodeDatum.attributes?.Acciones > 0 ? "#000" : "#6d828520"; // Color gris si no tiene acciones

        return (
            <g>
                <circle
                    cx="0"
                    cy="15"
                    r="60" // Radio ligeramente mayor
                    fill="#eaeaea"
                    stroke="none"
                />
                <circle
                    cx="0"
                    cy="0"
                    r="40"
                    // fill={esNodoRaiz ? "#000" : (isSelected ? "#fff" : iconColor)} // Aplicar el color dinámico
                    fill={isSelected ? "#fff" : iconColor} // Aplicar el color dinámico
                    onClick={() => handleG(nodeDatum)} // Abrir grafico del nodo
                    style={{
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                    }}
                    onMouseOver={(e) => e.target.setAttribute("fill", "#FFD700")} // Cambia color al pasar el ratón
                    onMouseOut={(e) => e.target.setAttribute("fill", isSelected ? "#fff" : iconColor)} // Vuelve al color original
                />
                <image
                    href={logo}
                    x="-25"
                    y="-25"
                    style={{
                        width: "50px",
                        height: "50px",
                        transition: "all 0.3s ease",
                        filter: nodeDatum.attributes?.Acciones > 0 ? 'none' : 'grayscale(100%)'
                        // filter: esNodoRaiz ? 'none' : (nodeDatum.attributes?.Acciones > 0 ? 'none' : 'grayscale(100%)')

                    }}
                    onClick={() => handleG(nodeDatum)} // Expandir/colapsar nodo
                />
                <text textAnchor="middle"
                    fontSize="20px"
                    fill="black"
                    strokeWidth="0.5"
                    x="0"
                    y="65"
                    onClick={() => handleNodeClick(nodeDatum)}
                    title={nodeDatum.name}
                >
                    {/* Mostrar el nombre del nodo en una linea maxima de 18 caracteres */}
                    {nodeDatum.name.substring(0, 18) + (nodeDatum.name.length > 18 ? "..." : "")}

                    {/* Mostrar el nombre del nodo en varias lineas */}
                    {/* {nodeDatum.name.split(' ').map((line, index) => (
                        <tspan key={index} x="0" dy={index === 0 ? "0" : "1.2em"}>
                            {line}
                        </tspan>
                    ))} */}
                </text>
            </g>
        );
    };

    // Función para mostrar el siguiente nivel del árbol
    // const expandirNivel = () => {
    //     setNivelMaximo((prevNivel) => prevNivel + 1);
    // };


    return (
        <>
            <Grid2
                container
                size={12}
                sx={{
                    p: 1,
                    background: "#eaeaea",
                    // background: "#1e1e1e",
                    border: 1,
                    borderColor: "#ffffff40",
                    borderRadius: 3,
                    overflow: "hidden",
                }}
                ref={containerRef}
            >
                {/* <Grid2 container size={12} sx={{}}>
                <Typography
                    sx={{
                        color: "#000",
                        fontSize: 24,
                        fontWeight: 800,
                    }}
                    align="center"
                >
                    Visualización Gráfica
                </Typography>
            </Grid2> */}

                {cargando ? (
                    <Grid2 item xs={12} style={{ height: "35px", margin: "auto" }}>
                        <CircularProgress color="primary" />
                    </Grid2>
                ) : (
                    <>
                        {arbol ? (
                            <Grid2 container size={12} style={{ height: "325px", cursor: "pointer" }}>
                                <Tree
                                    data={procesarNodos(arbol)} // Procesar nodos para reflejar colapsos
                                    orientation="vertical"
                                    translate={{ x: translateX, y: 35 }} // Usar translateX calculado
                                    // translate={{ x: 200, y: 25 }}
                                    collapsible={false} // No usar colapso automático
                                    // draggable={isTouchDevice}
                                    zoom={0.55}
                                    pathFunc="step"
                                    nodeSize={{ x: 200, y: 180 }} // Espacio entre nodos 
                                    separation={{ siblings: 2.5, nonSiblings: 1 }}
                                    renderCustomNodeElement={renderCustomNodeElement}
                                />
                            </Grid2>
                        ) : (
                            <Grid2 item xs={12}>
                                <Typography variant="body1" color="primary">
                                    No se encontró el árbol.
                                </Typography>
                            </Grid2>
                        )}

                        {hayMasNiveles && nivelVisible < nivelMaximo && (
                            <Grid2 item xs={12} sx={{ mt: 0 }}>
                                <Button variant="contained" color="primary" onClick={expandirNivel}>Mostrar siguiente nivel</Button>
                            </Grid2>
                        )}
                    </>
                )}


            </Grid2>

            {/* Modal para mostrar detalles del nodo */}
            {nodoSeleccionado && <Dialog
                open={modalAbierto}
                onClose={cerrarModal}
                PaperProps={{
                    style: {
                        backgroundColor: "#333333", // Fondo oscuro del modal
                        color: "#ffffff", // Texto blanco
                        borderRadius: "10px", // Bordes redondeados
                        padding: "10px",
                    },
                }}
            >
                <DialogTitle sx={{ fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                    Info
                </DialogTitle>

                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: '5px'
                    }}
                >
                    {/* Icono o avatar circular */}
                    <Box
                        sx={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50%",
                            backgroundColor: "#555555",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "15px",
                        }}
                    >
                        <img alt="Logo"
                            src={logo}
                            x="-25"
                            y="-25"
                            style={{
                                width: "50px",
                                height: "50px",
                                filter: nodoSeleccionado.attributes?.Acciones > 0 ? 'none' : 'grayscale(100%)'
                            }}
                        />
                    </Box>

                    {/* Información del nodo */}
                    <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", textAlign: "center", marginBottom: "0px" }}
                    >
                        {nodoSeleccionado?.name || "Nombre no disponible"}
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{ textAlign: "center", color: "#cccccc" }}
                    >
                        {nodoSeleccionado?.attributes?.Correo || "Correo no disponible"}
                    </Typography>
                </DialogContent>

                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button
                        onClick={cerrarModal}
                        variant="contained"
                        sx={{
                            backgroundColor: "#ff6100",
                            color: "#fff",
                            fontWeight: "bold",
                            "&:hover": { backgroundColor: "#ff6100c0" }
                        }}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>}

            {/* Modal del árbol */}
            <DialogoArbol
                open={modalArbolAbierto}
                onClose={cerrarModalArbol}
                nodoSeleccionado={nodoSeleccionadoArbol}
            />

        </>
    );
};

export default GraficoBinario;
