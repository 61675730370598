import React, { useState, useEffect, useRef } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Typography, Box } from "@mui/material";
import Tree from "react-d3-tree";
import { doc, getDoc } from "firebase/firestore";
import { fire } from "../../fire";
import logo from '../../Recursos/logo_redondo.svg';


const DialogoArbol = ({ open, onClose, nodoSeleccionado }) => {
    const [arbol, setArbol] = useState(null);
    const [cargando, setCargando] = useState(false);
    const [translateX, setTranslateX] = useState(0);
    const containerRef = useRef(null);

    const [modalAbierto, setModalAbierto] = useState(false);
    const [nodo, setNodo] = useState(nodoSeleccionado);

    // Función para construir el árbol del nodo seleccionado
    const construirArbol = async (idUsuario) => {
        if (!idUsuario) return null;
        setCargando(true);

        const docRef = doc(fire, "usuarios", idUsuario);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            setCargando(false);
            return null;
        }

        const datos = docSnap.data();
        const hijoIzquierdo = await construirArbol(datos.directo_izquierdo);
        const hijoDerecho = await construirArbol(datos.directo_derecho);

        setCargando(false);
        return {
            name: datos.nombre,
            id: idUsuario,
            correo: datos.correo,
            attributes: {
                Correo: datos.correo,
                Acciones: datos.acciones || 0,
            },
            children: [hijoIzquierdo, hijoDerecho].filter(Boolean),
        };
    };

    useEffect(() => {
        if (nodoSeleccionado?.id) {
            setNodo(nodoSeleccionado);  // Actualizar el estado del nodo
            construirArbol(nodoSeleccionado.id).then((arbolGenerado) => {
                setArbol(arbolGenerado);
            });
        }
    }, [nodoSeleccionado]);


    // Ajusta la posición del gráfico
    useEffect(() => {
        const updateTranslateX = () => {
            const screenWidth = window.innerWidth; // Usamos el ancho de la ventana
            const mitad = screenWidth / 2; // Centramos el gráfico en la mitad de la pantalla
            setTranslateX(mitad);
        };

        updateTranslateX();
        window.addEventListener('resize', updateTranslateX);

        return () => {
            window.removeEventListener('resize', updateTranslateX);
        };
    }, []);

    // Renderizado personalizado para los nodos
    const renderCustomNodeElement = ({ nodeDatum }) => {
        const iconColor = nodeDatum.attributes?.Acciones > 0 ? "#000" : "#6d828520"; // Color gris si no tiene acciones

        return (
            <g>
                {/* Círculo de fondo */}
                <circle
                    cx="0"
                    cy="15"
                    r="60" // Radio ligeramente mayor
                    fill="#eaeaea"
                    stroke="none"
                />
                <circle
                    cx="0"
                    cy="0"
                    r="40"
                    // fill={esNodoRaiz ? "#000" : (isSelected ? "#fff" : iconColor)} // Aplicar el color dinámico
                    fill={iconColor} // Aplicar el color dinámico
                    onClick={() => handleG(nodeDatum)} //

                    onMouseOver={(e) => e.target.setAttribute("fill", "#FFD700")} // Cambia color al pasar el ratón
                    onMouseOut={(e) => e.target.setAttribute("fill", iconColor)} // Vuelve al color original
                />
                <image
                    href={logo}
                    x="-25"
                    y="-25"
                    style={{
                        width: "50px",
                        height: "50px",
                        filter: nodeDatum.attributes?.Acciones > 0 ? 'none' : 'grayscale(100%)'
                    }}
                    onClick={() => handleG(nodeDatum)} // 
                />
                {/* Texto del nodo */}
                <text textAnchor="middle"
                    fontSize="20px"
                    fill="black"
                    strokeWidth="0.5"
                    x="0"
                    y="65"
                    // onClick={() => handleNodeClick(nodeDatum)}
                    title={nodeDatum.name}

                >
                    {/* Mostrar el nombre del nodo en una linea maxima de 18 caracteres */}
                    {nodeDatum.name.substring(0, 18) + (nodeDatum.name.length > 18 ? "..." : "")}
                </text>
            </g>
        );
    };

    // Función para manejar el clic en un nodo
    const handleG = (nodeData) => {
        console.log("Nodo seleccionado:", nodeData);
        setNodo(nodeData); // Guardar datos del nodo seleccionado
        setCargando(true);  // Mostrar indicador de carga mientras se actualiza el árbol

        // Recargar el árbol del nodo seleccionado
        construirArbol(nodeData.id).then((arbolGenerado) => {
            setArbol(arbolGenerado);
            setCargando(false);
        });
    };

    const handleNodeClick = (nodeData) => {
        console.log("Nodo seleccionado:", nodeData);
        setNodo(nodeData); // Guardar datos del nodo seleccionado
        setModalAbierto(true);
    };

    const cerrarModal = () => {
        setModalAbierto(false);
    };

    return (<>
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            PaperProps={{
                style: {
                    backgroundColor: "#eaeaea",
                    color: "#000",
                    borderRadius: "10px",
                },
            }}
        > <DialogContent sx={{ padding: 0, margin: 0 }} ref={containerRef}>
                <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
                    {nodo?.name || "Nodo"}
                    <Typography sx={{ textAlign: "center" }}>{nodo?.correo}</Typography>
                </DialogTitle>
                <DialogContent sx={{ height: "400px", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center", padding: 1, margin: 0 }}>

                    {cargando ? (
                        <CircularProgress color="primary" />
                    ) : arbol ? (
                        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Tree
                                data={arbol}
                                orientation="vertical"
                                translate={{ x: translateX, y: 50 }}
                                zoom={0.5}
                                pathFunc="step"
                                nodeSize={{ x: 200, y: 180 }}
                                separation={{ siblings: 2.5, nonSiblings: 1 }}
                                renderCustomNodeElement={renderCustomNodeElement} // Reutiliza renderizado personalizado
                            />
                        </Box>
                    ) : (
                        <Typography>No se pudo cargar el árbol.</Typography>
                    )}
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button
                        onClick={onClose}
                        variant="contained"
                        sx={{
                            backgroundColor: "#ff6100",
                            color: "#fff",
                            fontWeight: "bold",
                            "&:hover": { backgroundColor: "#ff6100c0" },
                        }}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>

        {/* Modal para mostrar detalles del nodo */}
        {nodo && <Dialog
            open={modalAbierto}
            onClose={cerrarModal}
            PaperProps={{
                style: {
                    backgroundColor: "#333333", // Fondo oscuro del modal
                    color: "#ffffff", // Texto blanco
                    borderRadius: "10px", // Bordes redondeados
                    padding: "10px",
                },
            }}
        >
            <DialogTitle sx={{ fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                Info
            </DialogTitle>

            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: '5px'
                }}
            >
                {/* Icono o avatar circular */}
                <Box
                    sx={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        backgroundColor: "#555555",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "15px",
                    }}
                >
                    <img alt="Logo"
                        src={logo}
                        x="-25"
                        y="-25"
                        style={{
                            width: "50px",
                            height: "50px",
                            filter: nodo.attributes?.Acciones > 0 ? 'none' : 'grayscale(100%)'
                        }}
                    />
                </Box>

                {/* Información del nodo */}
                <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", textAlign: "center", marginBottom: "0px" }}
                >
                    {nodo?.name || "Nombre no disponible"}
                </Typography>

                <Typography
                    variant="body1"
                    sx={{ textAlign: "center", color: "#cccccc" }}
                >
                    {nodo?.attributes?.Correo || "Correo no disponible"}
                </Typography>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "center" }}>
                <Button
                    onClick={cerrarModal}
                    variant="contained"
                    sx={{
                        backgroundColor: "#ff6100",
                        color: "#fff",
                        fontWeight: "bold",
                        "&:hover": { backgroundColor: "#ff6100c0" }
                    }}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>}
    </>
    );
};

export default DialogoArbol;
